
import MediaTVFormInput from "@/views/berita/components/MediaTVFormInput.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface IFormedMedia {
  title: string;
  source: string;
  account: string;
  program: string;
  content: string;
  date: string;
  emotion: string;
  file_source: string;
  journalist: string;
  page: string;
  rate_bw: string;
  rate_fc: string;
  sentiment: string;
  is_news_photo: string;
  jenis_data: string;
  ad_value: number;
  duration: number;
  is_validated: boolean;
}

export default defineComponent({
  name: "add-more-news",
  components: {
    ErrorMessage,
    Field,
    MediaTVFormInput,
  },
  setup() {
    const form_count = ref(1);
    const save_button = ref();
    const source = ref("media_tv");
    const options = ref({
      source: [
        {
          title: "Media Cetak",
          value: "media_cetak",
        },
        {
          title: "Media TV",
          value: "media_tv",
        },
        {
          title: "Media Online",
          value: "news",
        },
      ],
    });
    const news_data = ref<Array<IFormedMedia>>([
      {
        title: "",
        source: source.value,
        account: "",
        program: "",
        content: "",
        date: "",
        emotion: "",
        file_source: "",
        journalist: "",
        page: "",
        rate_bw: "",
        rate_fc: "",
        sentiment: "",
        is_news_photo: "",
        jenis_data: "",
        ad_value: 0,
        duration: 0,
        is_validated: false,
      },
    ]);
    const is_disable_save = computed(() => {
      const is_not_validated = news_data.value.filter(
        (el: IFormedMedia) => !el.is_validated
      );
      if (is_not_validated.length > 0) {
        return true;
      }
      return false;
    });

    // FUNCTION
    const addNewForm = () => {
      const form = {
        title: "",
        source: source.value,
        account: "",
        program: "",
        content: "",
        date: "",
        emotion: "",
        file_source: "",
        journalist: "",
        page: "",
        rate_bw: "",
        rate_fc: "",
        sentiment: "",
        is_news_photo: "",
        jenis_data: "",
        ad_value: 0,
        duration: 0,
        is_validated: false,
      };
      if (form_count.value < 1) {
        form_count.value = 1;
      } else if (form_count.value > 50) {
        form_count.value = 50;
      }
      for (let i = 0; i < form_count.value; i++) {
        news_data.value.push(form);
      }
    };
    const deleteForm = (index: number) => {
      Swal.fire({
        title: `Apakah Anda Yakin Ingin Menghapus "Formulir Berita #${
          index + 1
        }"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          news_data.value.splice(index, 1);
        }
      });
    };
    const hideAllForm = () => {
      for (let i = 0; i < news_data.value.length; i++) {
        var element = document.getElementById("form_" + i);
        if (element) {
          element.classList.remove("show");
        }
      }
    };
    const resetSelectedForm = (index: number) => {
      news_data.value[index] = {
        title: "",
        source: source.value,
        account: "",
        program: "",
        content: "",
        date: "",
        emotion: "",
        file_source: "",
        journalist: "",
        page: "",
        rate_bw: "",
        rate_fc: "",
        sentiment: "",
        is_news_photo: "",
        jenis_data: "",
        ad_value: 0,
        duration: 0,
        is_validated: false,
      };
    };
    const saveAllData = () => {
      if (!is_disable_save.value) {
        save_button.value.setAttribute("data-kt-indicator", "on");
        const params = news_data.value.map(
          ({
            title,
            source,
            account,
            program,
            content,
            date,
            emotion,
            file_source,
            journalist,
            page,
            rate_fc,
            rate_bw,
            sentiment,
            jenis_data,
          }) => ({
            title,
            source,
            account,
            program,
            content,
            date,
            emotion,
            file_source,
            journalist,
            page,
            rate_fc,
            rate_bw,
            sentiment,
            jenis_data,
          })
        );
        ApiService.post("/streams/save-bulk", { data: params })
          .then((response) => {
            if (response.status == 200) {
              news_data.value = [
                {
                  title: "",
                  source: source.value,
                  account: "",
                  program: "",
                  content: "",
                  date: "",
                  emotion: "",
                  file_source: "",
                  journalist: "",
                  page: "",
                  rate_bw: "",
                  rate_fc: "",
                  sentiment: "",
                  is_news_photo: "",
                  jenis_data: "",
                  ad_value: 0,
                  duration: 0,
                  is_validated: false,
                },
              ];
              Swal.fire({
                position: "top-end",
                icon: "success",
                toast: true,
                title: "Berhasil! Berita Telah Disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            } else {
              Swal.fire({
                position: "top-end",
                icon: "error",
                toast: true,
                title: "Gagal! Berita gagal Disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          })
          .catch(() => {
            Swal.fire({
              position: "top-end",
              icon: "error",
              toast: true,
              title: "Gagal! Terjadi Kesalahan Saat Menyimpan Data",
              showConfirmButton: false,
              timer: 3000,
            });
          })
          .finally(() => {
            save_button.value.setAttribute("data-kt-indicator", "off");
          });
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Input Berita", ["Berita"]);
    });

    return {
      form_count,
      save_button,
      is_disable_save,
      source,
      options,
      news_data,
      addNewForm,
      deleteForm,
      hideAllForm,
      resetSelectedForm,
      saveAllData,
    };
  },
});
